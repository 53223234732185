import React, { useState } from 'react';
import clickSoundWrap from "../../../utils/clickSound";
import useOranizerData from "../../../hooks/useOranizerData";
import ModalWindow from "../../ModalWindow/ModalWindow";
import getDefaultIcon from "../../../utils/getDefaultIcon";
import ProcessItem from "../../ProcessItem/ProcessItem";

const TasksPage = ({ organizer }) => {
    const [modal, setModal] = useState({ type: "none", args: {} });

    async function onSelect(type, args) {
        if (args[0] && args[0] === "close") {
            setModal("none");
            return;
        }

        if (type === "add_process") {
            let imageId = undefined;
            if (args[2]) imageId = await organizer.images.addImage(args[2].buffer, args[2].type);
            organizer.templates.addProcessTemplate(args[0], args[1], imageId, args[3], args[4]);
        }
        else if (type === "add_task") {
            organizer.templates.addTaskToProcessTemplate(args[0], args[1]);
        }
        else if (type === "click_on_process") {
            if (args[0] === "delete") {
                organizer.templates.removeTaskFromProcessTemplate(args[1], args[2]);
            }
        }
        setModal("none");
    }

    function addProcess() {
        setModal({ type: "add_process", args: {} });
    }

    function addTask(processId) {
        setModal({ type: "add_task", args: { processId } });
    }

    function deleteProcess(processId) {
        organizer.templates.removeProcessTemplate(processId);
    }

    function clickOnTask(processId, taskId) {
        setModal({ type: "click_on_process", args: { processId, taskId, hideCompleted: true } });
    }

    function moveTaskUp(processId, taskId) {
        organizer.templates.moveUpTaskInProcessTemplate(processId, taskId);
    }

    function moveTaskDown(processId, taskId) {
        organizer.templates.moveDownTaskInProcessTemplate(processId, taskId);
    }

    return (
        <>
            <button className="cyber_btn" id="newQuestBtn" onClick={clickSoundWrap(2, addProcess)}>
                <img src={require("../../../shared/img/plus.png")} alt="plus" />
            </button>
            <section className="out_chat d-flex column center_h" id="out_chat">
                <div className="todos-wrapper">
                    {organizer.data.templates.map(i =>
                        <ProcessItem key={i.id} organizer={organizer} process={i} addProcess={addProcess} deleteProcess={deleteProcess} addTask={addTask} clickOnTask={clickOnTask}
                            moveTaskUp={moveTaskUp} moveTaskDown={moveTaskDown} />
                    )}
                    {organizer.data.templates.length === 0 && <div style={{ marginTop: "2vh", fontSize: "2vh", color: "#191e22", fontWeight: "1000" }}>Нет созданных шаблонов процессов</div>}
                </div>

            </section>

            <ModalWindow windowType="tasks" organizer={organizer} type={modal.type} args={modal.args} onSelect={onSelect} />
        </>
    );
};

export default TasksPage;
import React, {useState} from 'react';
import getFullDate from "../../utils/getFullDate";
import cl from "./DaySlider.module.css";

const DaySlider = ({currentDate, setCurrentDate}) =>
{
    let startMonday = new Date();

    while(startMonday.getDay() !== 1)
    {
        startMonday.setDate(startMonday.getDate() - 1);
    }

    const [weekStart, setWeekStart] = useState(new Date(startMonday));
    startMonday.setTime(weekStart.getTime());

    function getNameOfDay(date)
    {
        let dayOfWeek = date.getDay();
        if(dayOfWeek === 0) return "Вс";
        else if(dayOfWeek === 1) return "Пн";
        else if(dayOfWeek === 2) return "Вт";
        else if(dayOfWeek === 3) return "Ср";
        else if(dayOfWeek === 4) return "Чт";
        else if(dayOfWeek === 5) return "Пт";
        else if(dayOfWeek === 6) return "Сб";
    }

    function generateDays()
    {
        const days = [];

        while(startMonday.getDay() !== 0)
        {
            const fullDate = getFullDate(startMonday);
            if(fullDate === currentDate) days.push(<div key={fullDate} className={cl.dayContainerSelected} onClick={() => setCurrentDate(fullDate)}><div className={cl.dayOfWeek}>{getNameOfDay(startMonday)}</div><div className={cl.day}>{startMonday.getDate()}</div></div>);
            else days.push(<div key={fullDate} className={cl.dayContainer} onClick={() => setCurrentDate(fullDate)}><div className={cl.dayOfWeek}>{getNameOfDay(startMonday)}</div><div className={cl.day}>{startMonday.getDate()}</div></div>);
            startMonday.setDate(startMonday.getDate() + 1);
        }

        const fullDate = getFullDate(startMonday);
        if(fullDate === currentDate) days.push(<div key={fullDate} className={cl.dayContainerSelected} onClick={() => setCurrentDate(fullDate)}><div className={cl.dayOfWeek}>{getNameOfDay(startMonday)}</div><div className={cl.day}>{startMonday.getDate()}</div></div>);
        else days.push(<div key={fullDate} className={cl.dayContainer} onClick={() => setCurrentDate(fullDate)}><div className={cl.dayOfWeek}>{getNameOfDay(startMonday)}</div><div className={cl.day}>{startMonday.getDate()}</div></div>);
        return days;
    }


    function prevWeek()
    {
        setWeekStart(prev =>
        {
            let newDate = new Date(prev);
            newDate.setDate(newDate.getDate()-7);
            return newDate;
        });
    }

    function nextWeek()
    {
        setWeekStart(prev =>
        {
            let newDate = new Date(prev);
            newDate.setDate(newDate.getDate()+7);
            return newDate;
        });
    }



    return (
        <div className={cl.daySlider}>
            <div className={cl.prev} onClick={prevWeek}><img src={require("../../shared/img/arrow_up_dark.png")}/></div>
            <div className={cl.daysContainer}>
                {generateDays()}
            </div>
            <div className={cl.next} onClick={nextWeek}><img src={require("../../shared/img/arrow_up_dark.png")}/></div>
        </div>
    );
};

export default DaySlider;
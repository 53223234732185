const visualizeTime = (value, simplified) => {
    if(!value) return "";

    var sec_num = value; // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if(!simplified) {
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
    }
   

    if(hours > 0) {
        return hours+' час '+minutes+' мин';
    } else {
        return minutes+' мин';
    }
    
};

export default visualizeTime;
import React, {useEffect} from 'react';
import clickSoundWrap from "../../utils/clickSound";
import AddProcessModal from "./AddProcessModal";
import AddTaskModal from "./AddTaskModal";
import ChangeTimeModal from "./ChangeTimeModal";
import ClickOnProcessModal from "./ClickOnProcessModal";
import AddExistingTaskModal from "./AddExistingTaskModal";
import ClockTicking from "./ClockTicking";

const ModalWindow = ({windowType, organizer, type, args, onSelect, ...props}) =>
{
    const modal = renderModal();
    if(!modal) return;

    function renderModal()
    {
        if(type === "add_process") return <AddProcessModal organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else if(type === "add_task") return <AddTaskModal organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else if(type === "add_existing_task") return <AddExistingTaskModal organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else if(type === "change_time") return <ChangeTimeModal organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else if(type === "click_on_process") return <ClickOnProcessModal windowType={windowType} organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else if(type === "clock_ticking") return <ClockTicking organizer={organizer} args={args} onSelect={(...args) => onSelect(type, args)}/>;
        else return undefined;
    }

    return (
        <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                {modal} 
            </div>
        </div>
    );
};

export default ModalWindow;
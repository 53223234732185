import React, { useEffect, useState, useRef } from 'react';
import useOranizerData from "../../../hooks/useOranizerData";
import getFullDate from "../../../utils/getFullDate";
import ProcessItem from "../../ProcessItem/ProcessItem";
import ModalWindow from "../../ModalWindow/ModalWindow";
import Countdown from "../../MainWindow/Countdown";
import ProcessesBar from "../../MainWindow/ProcessesBar";
import { useContext } from 'react';
import Countdownss from 'react-countdown';

// const TimerContext = React.createContext();

const MainPage = ({ organizer }) => {
    const ref = useRef();
    const countdownRef = useRef();

    //const [time, setTime] = useState(0);

    // useEffect(()=>{
    //     if (run=== true && time < 1440){
    //         var timerID = setInterval(() => tick(time),1000);

    //         return function cleanup(){
    //             clearInterval(timerID);
    //         };
    //     }
    // },[run, time]);

    const [time, setTime] = useState(getTimestampInSeconds());
    const [toShow, setToShow] = useState([]);

    const [barInterval, setBarInterval] = useState("0");

    const [activeCountdown, setActiveCountdown] = useState(false);
    const [activeCountdownValue, setActiveCountdownValue] = useState(false);
    const [activeProcess, setActiveProcess] = useState({});

    const [currentDay, setCurrentDay] = useState(getFullDate(new Date()));
    const [modal, setModal] = useState({ type: "none", args: {} });

    const [triggerThing, setTriggerThing] = useState(0);



    function onSelect(type, args) {
        if (args[0] && args[0] === "close") {
            setModal("none");
            return;
        }

        if (type === "add_existing_task") {
            if (args[0] === "selectTask") {
                organizer.week.addTaskToDay(args[1], args[2], currentDay);
            }
            else if (args[0] === "selectProcess") {
                organizer.week.addProcessToDay(args[1], currentDay);
            }
        }
        else if (type === "change_time") {
            organizer.week.changeTimeInProcess(currentDay, args[0], args[1], args[2], args[3]);
        }
        else if (type === "click_on_process") {
            if (args[0] === "delete") {
                organizer.week.removeTaskFromDay(args[1], args[2], currentDay);
            }
            if (args[0] === "return") {
                organizer.week.returnTaskFromDay(args[1], args[2], currentDay);
            }
            else if (args[0] === "mark_completed") {
                organizer.week.markCompleteTaskInDay(args[1], args[2], currentDay);
            }
        }
        setModal("none");
    }

    function addProcess() {
        setModal({ type: "add_existing_task", args: { type: "selectProcess", processes: organizer.data.templates } });
    }

    function addTask(processId) {
        let plannedProcess = organizer.data.planned[currentDay].find(i => i.id === processId);
        let processTemplateId = plannedProcess.templateId;
        setModal({ type: "add_existing_task", args: { type: "selectTask", processId, tasks: organizer.data.templates.find(i => i.id === processTemplateId).tasks } });
    }

    function deleteProcess(processId) {
        organizer.week.removeProcessFromDay(processId, currentDay);
    }

    function clickOnTask(processId, taskId) {
        let plannedProcess = organizer.data.planned[currentDay].find(i => i.id === processId)
        let plannedTask = plannedProcess.tasks.find(i => i.id === taskId);

        setModal({ type: "click_on_process", args: { processId, taskId, hideCompleted: plannedTask.completed } });
    }

    function moveTaskUp(processId, taskId) {
        organizer.week.moveUpTaskInProcess(currentDay, processId, taskId);
    }

    function moveTaskDown(processId, taskId) {
        organizer.week.moveDownTaskInProcess(currentDay, processId, taskId);
    }

    function onClickTime(processId, type) {
        let process = organizer.data.planned[currentDay].find(i => i.id === processId);

        let startTime = { ...process.startTime };
        let endTime = { ...process.endTime };
        console.log(process.countdown)
        let countdown = process.countdown;
        setModal({ type: "change_time", args: { processId, startTime, endTime, type, countdown } });
    }

    function getTimestampInSeconds() {
        return Math.floor(Date.now() / 1000)
    }

    const getContentData = (toShow) => {
        return new Promise(() => {

            if (!localStorage.getItem('activeProcess')) {
                return toShow[0];
            } else {
                let result = toShow.filter(i => {
                    if (i.id == localStorage.getItem('activeProcess')) return true;
                    else return false;
                });

                setActiveProcess(result[0]);
            }
        });
    };

    const cleanupCountdown = () => {
        //countdownRef.clearInterval(countdownRef.barInterval);
        if (countdownRef.current && countdownRef.current.end) {
            //countdownRef.current.end();
        }
    };

    // useEffect(() => {
    //     let s = (interval) => {
    //         console.log(interval)
    //         //clearInterval(interval);
    //     }

    //     return () => clearInterval(barInterval);
    // }, []);

    useEffect(() => {
        return () => {
            clearInterval(barInterval);
        };
    }, [barInterval]);



    useEffect(() => {


        const now = new Date();
        if (!organizer.data.planned.hasOwnProperty(getFullDate(now))) {
            setToShow([]);
            return;
        }

        const today = organizer.data.planned[getFullDate(now)];
        const split = getFullDate(now).split(".");
        const toShow = today.filter(i => {


            let start = new Date(parseInt(split[2]), parseInt(split[1]) - 1, parseInt(split[0]), i.startTime.hour, i.startTime.minute, 0);
            let end = new Date(parseInt(split[2]), parseInt(split[1]) - 1, parseInt(split[0]), i.endTime.hour, i.endTime.minute, 59);

            //if (i.type === "work" && start >= now) return true;

            if (i.type === "rest" || i.type === "work") return true;
            //if(start <= now && end >= now) return true;
            if (start <= now && end >= now) return true;
            else if (end < now) {
                //console.log(i);
                //organizer.week.setProcessToNextDay(i.id, getFullDate(now));
                // organizer.week.addProcessToDay(args[1], currentDay);
                //organizer.week.removeProcessFromDay();
                return false;
            }
            else return false;
        });

        setToShow(toShow);

        //setActiveProcess(await getContentData(toShow))

        if (!localStorage.getItem('activeProcess')) {
            setActiveProcess(toShow[0]);
        } else {
            let result = toShow.filter(i => {
                if (i.id == localStorage.getItem('activeProcess')) return true;
                else return false;
            });

            if (result.length == 0 && toShow.length != 0) {
                localStorage.setItem('activeProcess', toShow[0].id);
                setActiveProcess(toShow[0]);
                return;
            }
            
            setActiveProcess(result[0]);
            if(result.length > 0) {
                setActiveCountdown(result[0].countDownActive)
            } else {
                setActiveCountdown(false)

            }
        }

    }, [organizer.data, time]);

    
    // useEffect(() => {
    //     if (activeProcess && activeProcess.countDownActive !== undefined) {
    //         setActiveCountdown(activeProcess.countDownActive);
    //     }
    // }, [activeProcess]);

    function controlCountDown(value) {
        const activeProcessWithCountdown = toShow.find(i => i.countDownActive === true);

        // Check if there's an active process with countdown and it's different from the currently active process
        if (activeProcessWithCountdown !== undefined && activeProcess.id !== activeProcessWithCountdown.id) {
            // End the countdown for the previously active process
            organizer.week.endCountdown(getFullDate(new Date()), activeProcessWithCountdown.id);
            organizer.week.startCountdown(getFullDate(new Date()), activeProcess.id);
        }

        setActiveCountdown(value);
    }

    function controlActiveProc(value) {
        
        console.log(value)
        if(activeCountdown) {
            setActiveCountdownValue(false);
        } else {
            //setActiveCountdownValue(true);
        }
        

        if(value.countDownActive) {
            setActiveCountdownValue(true);
        }
        setActiveProcess(value);
    }

    useEffect(() => {
        setActiveCountdownValue(activeCountdown);
    }, [activeCountdown]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            // const {time} = useTimer();
            //setTime(getTimestampInSeconds());
            setCurrentDay(getFullDate(new Date()));
        }, 1000); //1000);

        return () => clearInterval(interval);
    }, []);

    function setIntervalFunc(value) {
        //console.log(value)
        setBarInterval(value);
    }

    return (

        <>
            {activeProcess && <Countdown ref={countdownRef} triggerThing={triggerThing} 
            setActiveCountdown={setActiveCountdown} setBarIntervalExternal={setIntervalFunc} organizer={organizer} data={toShow} activeProcess={activeProcess} activeCountdown={activeCountdown} />}
            <ProcessesBar organizer={organizer} data={toShow} setActiveProcess={controlActiveProc} />
            {activeProcess && (<ProcessItem activeCountdown={activeCountdownValue} setActiveCountdown={controlCountDown} mainPage={true}
                key={activeProcess.id} organizer={organizer} process={activeProcess} deleteProcess={deleteProcess} addTask={addTask} clickOnTask={clickOnTask}
                moveTaskUp={moveTaskUp} moveTaskDown={moveTaskDown} onClickTime={onClickTime} hideAddTask={true} hideArrows={true}
                allowTaskDeleting={false} />)}
            <ModalWindow organizer={organizer} type={modal.type} args={modal.args} onSelect={onSelect} />

        </>
    );
};

// const useTimer = () => useContext(TimerContext)

export default MainPage;
import React from 'react';
import ProcessItem from "../../ProcessItem/ProcessItem";

const HistoryPage = ({organizer}) => {

    function remap(obj)
    {
        console.log(organizer)
        return {...obj};
    }

    return (
        <>
            {organizer.data.history.map((i,index) => <ProcessItem organizer={organizer} key={index} process={remap(i)} hideTasks={true} readOnly={true}/> )}
        </>
    );
};

export default HistoryPage;
import React, { useEffect, useState } from 'react';
import clickSoundWrap from "../../utils/clickSound";

const ChangeTimeModal = ({ args, onSelect, setModal }) => {

    const [startHour, setStartHour] = useState(args.startTime.hour);
    const [startMinute, setStartMinute] = useState(args.startTime.minute);
    const [endHour, setEndHour] = useState(args.endTime.hour);
    const [endMinute, setEndMinute] = useState(args.endTime.minute);

    const convertSecondsToHours = (seconds) => {

        const hours = Math.floor(seconds / 3600)

        return hours || 0;
    }

    const convertSecondsToMinutes = (seconds) => {
        const minutes = Math.floor((seconds % 3600) / 60)

        return minutes || 0
    }

    const [countHours, setCountHours] = useState(convertSecondsToHours(args.countdown));
    const [countMinutes, setCountMinutes] = useState(convertSecondsToMinutes(args.countdown));

    function complete() {
        //if(startHour > endHour) return;
        //if(startHour === endHour && startMinute >= endMinute) return;


        if (args.countdownTime) {
            onSelect("selectProcess", countHours * 60 * 60 + countMinutes * 60);
            onSelect("close")
        }

   
            onSelect(args.processId, { hour: startHour, minute: startMinute }, { hour: endHour, minute: endMinute },
                { countHours: countHours, countMinutes: countMinutes });

    }

    useEffect(() => {
        window.document.body.style.overflow = "hidden";
        window.document.body.style.paddingRight = "13px";
        return () => {
            window.document.body.style.overflow = "";
            window.document.body.style.paddingRight = "";
        };
    }, []);

    useEffect(() => {
        function onKeyUp(e) {
            if (e.keyCode === 13) complete();
        }

        window.addEventListener("keyup", onKeyUp);
        return () => window.removeEventListener("keyup", onKeyUp);
    }, [args.processId, startHour, startMinute, endHour, endMinute]);

    return (
        <>
            <span className="close" onClick={clickSoundWrap(6, () => onSelect("close"))}>&times;</span>
            <section className="section2 d-flex column center_w center_h" id="section2">
                <div className="time_and_image d-flex center_h jc-between">

                {args.type === "work" && <div className="time_period">
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время от (часы)" value={startHour} onChange={e => setStartHour(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input"} style={{ marginRight: "4vh" }} placeholder="Время от (минуты)" value={startMinute} onChange={e => setStartMinute(parseInt(e.target.value) || 0)} />
                    </div>}

                    {!args.countdownTime && args.type !== "rest" && args.type !== "work" && <div className="time_period">
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время от (часы)" value={startHour} onChange={e => setStartHour(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input"} style={{ marginRight: "4vh" }} placeholder="Время от (минуты)" value={startMinute} onChange={e => setStartMinute(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время до (часы)" value={endHour} onChange={e => setEndHour(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время до (минуты)" value={endMinute} onChange={e => setEndMinute(parseInt(e.target.value) || 0)} />
                    </div>}

                    {(args.type === "rest" || args.type === "other" || args.type === "work" || args.countdownTime) && <div className="time_period">
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время от (часы)" value={countHours} onChange={e => setCountHours(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input"} style={{ marginRight: "4vh" }} placeholder="Время от (минуты)" value={countMinutes} onChange={e => setCountMinutes(parseInt(e.target.value) || 0)} />
                    </div>}
                </div>
                <button id="cyber_btn" className="d-flex center_h center_w cyber_btn3" onClick={clickSoundWrap(1, complete)}>
                    Отправить
                </button>
            </section>
        </>
    );
};

export default ChangeTimeModal;
import React, { useEffect, useState, useRef } from 'react';
import cl from "./Countdown.module.css";
import Countdown from 'react-countdown';
import visualizeTime from "../../../src/utils/visualizeTime";
import ProgressBar from "@ramonak/react-progress-bar";
import './Countdown.module.css'
import getFullDate from "../../utils/getFullDate";

const CountdownElement = ({ organizer, setBarIntervalExternal, data, activeProcess, activeCountdown, setActiveCountdown }) => {

    const [activeProcessHere, setActiveProcessHere] = useState({});
    const [additionalActiveProcess, setAdditionalActiveProcess] = useState({});
    const [time, SetTime] = useState(0);
    const [barInterval, setBarInterval] = useState(0);
    const [barWidth, setBarWidth] = useState("100%");
    const [timeChange, setTimeChange] = useState(0);

    const ref = useRef();
    const progressBar = useRef();

    useEffect(() => {

      //  console.log(ad)

        try {
            setActiveProcessHere(activeProcess);
            //setActiveCountdown(activeProcess.countDownActive);
            setAdditionalActiveProcess(activeProcess);
            getBarWitdh();
            console.log(activeProcess)
        } catch (error) {
            
        }

    }, [activeProcess]);

    useEffect(() => {
        //console.log("Count Start FUnc")

        //console.log(activeProcess)
        if (activeCountdown) {
            start();
        } else {
            end();
        }
    }, [activeCountdown])

    // useEffect(() => {
    //     if (activeProcessHere && activeProcessHere.countDownActive !== undefined) {
    //         setActiveCountdown(activeProcessHere.countDownActive);
    //     }
    // }, [activeProcessHere]);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span>
                Завершено
            </span>
        } else {
            // Render a countdown
            return (
                <span>
                    {(hours < 10) ? "0" + hours : hours}:
                    {(minutes < 10) ? "0" + minutes : minutes}:
                    {(seconds < 10) ? "0" + seconds : seconds}
                </span>
            );
        }
    };

    useEffect(() => {
        // Update the external barInterval when it changes
        setBarIntervalExternal(barInterval);
    }, [barInterval, setBarIntervalExternal]);

    useEffect(() => {
        return () => clearInterval(barInterval); // Clear the barInterval on unmount
    }, []);

    useEffect(() => {
        let s = (interval) => {
            //console.log(interval)
            //clearInterval(interval);
        }
        return () => s(activeProcessHere);
    }, []);

    useEffect(() => {
       // clearInterval(barInterval);
        if (activeProcessHere && activeProcessHere.countdownTimer) {


            let s = 0;
            let interval = setInterval(() => {
                if (activeProcessHere.countDownActive) {
                    s = activeProcessHere.countdownTimer - (Date.now() - activeProcessHere.countStartTime) / 1000;
                   let g = (activeProcessHere.countdownTimer - s) * 1000;


                    //setTimeChange(prevTimeChange => prevTimeChange + 200);
                    //console.log(g)
                   // console.log(gg(0))

                    setTimeChange(g);
                }

                //let s = timeChange + 200;
                //
            }, 10);
            s = (activeProcessHere.countdownTimer * 1000) - ((Date.now() - activeProcessHere.countStartTime) / 1000);


            setBarInterval(interval);
            let value = activeProcessHere.countdownTimer * 1000;

            if (activeProcessHere.countStartTime != 0) {
                SetTime((activeProcessHere.countdownTimer - (Date.now() - activeProcessHere.countStartTime) / 1000) * 1000);
            } else {
                SetTime(value);
            }
            
        }

        return () => clearInterval(barInterval);

    }, [activeProcessHere]);

    function getOverallTime() {
        let result = 0;

        data.forEach(element => {
            if (element.countdown) {
                result += element.countdown;
            }
        });

        return visualizeTime(result, true);
    }

    function getOverallTasks() {

        let tasksNumber = 0, completedTasks = 0;

        data.forEach(element => {
            if (element.tasks) {
                element.tasks.forEach((task) => {
                    tasksNumber++;
                    if (task.completed) completedTasks++;
                })
            }


        });

        return completedTasks + " з " + tasksNumber;
    }

    function getTimeLeft() {
        if(!activeProcess.countDownActive) return Date.now() + time;
        //return Date.now() + (time - timeChange);
        return Date.now() + ((activeProcess.countdownTimer - timeChange/1000) * 1000);
    }

    function getBarWitdh() {
        //let value = ((time - timeChange) / 1000) / activeProcess.countdown;
        let value = (activeProcess.countdownTimer - timeChange/1000) / activeProcess.countdown;
        if (value < 0) end();
        setBarWidth(`${100 - (value * 100)}%`);
    }

    useEffect(() => {
        if (activeProcess.countdownTimer <= 0) {
            setBarWidth(`${100}%`);
            return;
        }
        if (activeProcess && activeProcess.countdown) {
            getBarWitdh();
        }
    }, [time, timeChange, activeProcess]);


    function startCountdownFunc() {
        // // Check if there is an active process
        // if (Object.keys(additionalActiveProcess).length > 0) {
        //     // Start the countdown for the additionalActiveProcess
        //     //organizer.week.startCountdown(getFullDate(new Date()), additionalActiveProcess.id);

        //     // Start the local timer
        //     start();
        // }
    }

    useEffect(() => {
        //return () => { clearInterval(barInterval); }
    }, []);

    function start() {
        if (Object.keys(activeProcess).length == 0) return;

        console.log(activeProcess.countDownActive)
        if(activeProcess.countDownActive) return;
        organizer.week.startCountdown(getFullDate(new Date()), activeProcess.id);

        //setBarInterval(interval);
    }




    function end(initialAccess) {
        //if(barInterval) return;

        if (Object.keys(additionalActiveProcess).length == 0) {
            return;
        }

        clearInterval(barInterval);

        if (activeProcess.id > 0) {
            setTimeChange(0);

            organizer.week.endCountdown(getFullDate(new Date()), activeProcess.id);
            //organizer.week.changeCountdownTime(getFullDate(new Date()), additionalActiveProcess.id, value);
            setActiveCountdown(false);
        }
    }

    const barStyle = {
        width: barWidth,
    }

    return (

        <div className={cl.countdown}>
            <div className={"infoBlock " + cl.infoBlock}>
                <div className="overralTime" onClick={() => { ref.current.pause(); }}>
                    {"Всего: " + getOverallTime()}
                </div>
                <div className="overralTasks"  >
                    {"Выполнено: " + getOverallTasks()}
                </div>
            </div>
            {activeProcess && <Countdown onStart={startCountdownFunc} onStop={end} onPause={end} ref={ref} date={getTimeLeft()} renderer={renderer} />}
            {/* {activeProcess && <ProgressBar className={cl.progress}  completed={100} customLabel=" " />} */}
            {activeProcess && <div className={cl.progress} ref={progressBar}>
                <div className={cl.bar} style={barStyle}>
                    <div class="progress-value"></div>
                </div>
            </div>}
        </div>


    );

};

export default CountdownElement;
import React, { useEffect, useState, useRef } from 'react';
import getDefaultIcon from "../../utils/getDefaultIcon";
import clickSoundWrap from "../../utils/clickSound";
import cl from "./ProcessItem.module.css";
import TaskItem from "./TaskItem";
import visualizeTime from "../../../src/utils/visualizeTime";

const ProcessItem = ({organizer, process, mainPage, activeCountdown, setActiveCountdown, 
    addProcess, deleteProcess, addTask, clickOnTask, moveTaskUp, moveTaskDown, readOnly, onClickTime, allowTaskDeleting, ...props}) =>
{

    const [countdownActive, setCountdownActive] = useState(activeCountdown);

    useEffect(() => {
        setCountdownActive(activeCountdown);
    }, [activeCountdown]);

    function getIcon()
    {
        if(process.type === "rest") return <img src={require("../../shared/img/rest.png")}/>;
        else if(process.type === "work") return <img src={require("../../shared/img/work.png")}/>;
        else return;
    }

    function addTaskWrap(processId)
    {
        return (e) => {
            e.stopPropagation();
            if(addTask) addTask(processId);
        };
    }

    function deleteProcessWrap(processId)
    {
        return (e) => {
            e.stopPropagation();
            if(deleteProcess) deleteProcess(processId)
        };
    }

    function onClickTimeWrap(processId)
    {
        return (e) => {
            e.stopPropagation();
            if(onClickTime) onClickTime(processId, process.type)
        };
    }

    function renderTasks()
    {
        if(props.hideTasks) return;
        if(Object.keys(process).length === 0) return;
        //return;
        return (
            <>
                {process.tasks.map(i => <TaskItem key={i.id} processId={process.id} i={i} moveTaskUp={moveTaskUp} moveTaskDown={moveTaskDown} clickOnTask={clickOnTask} hideArrows={props.hideArrows}/>)}
                {!props.hideAddTask && <div className="shadow_container_add" onClick={clickSoundWrap(2, addTaskWrap(process.id))}>
                    <img src={require("../../shared/img/plus.png")} alt="plus"/>
                </div>}
            </>
        );
    }

    function getTaskCountText()
    {
        if(!(!readOnly && process.tasks)) return;

        let txt = "задач";
        let count = process.tasks.length;
        if(count >= 20) count %= 10;
        if(count === 1) txt = "задача";
        else if(count >= 2 && count <= 4) txt = "задачи";

        return `${count} ${txt}`;
    }

    function toggleCountdown() {
        if(setActiveCountdown && !countdownActive) {
            setCountdownActive(true);
            setActiveCountdown(true)
        } else {
            setCountdownActive(false);
            setActiveCountdown(false)
        }
    }

    return (
        <React.Fragment key={process.id}>
            <div  className="todo-item d-flex center_h jc-between">
                <div className="task-content d-flex center_h jc-between">
                    <div className="image-container">
                        <img src={organizer.images.tryGetImageCache(process.iconId) || getDefaultIcon()} loading={"lazy"} alt="Task Image" className="task-image"/>
                    </div>
                    <div className="text_info d-flex column jc-between">
                        <div className="description-icon d-flex center_h">
                            <div className="description">{process.name}</div>
                            <div className="icon">{getIcon()}</div>
                        </div>
                        { process.type === "rest" && <div className="time"><span onClick={onClickTimeWrap(process.id)}> 
                            {visualizeTime(process.countdown, false)}
                            </span></div>}
                        { process.type === "other" && <div className="time"><span onClick={onClickTimeWrap(process.id)}> 
                            {process.startTime.hour.toString().padStart(2, '0')}:{process.startTime.minute.toString().padStart(2, '0')}  - {process.endTime.hour.toString().padStart(2, '0')}:{process.endTime.minute.toString().padStart(2, '0')} | {visualizeTime(process.countdown, false)}
                            </span></div>}
                        { process.type === "work" && <div className="time"><span onClick={onClickTimeWrap(process.id)}>
                            {process.startTime.hour.toString().padStart(2, '0')}:{process.startTime.minute.toString().padStart(2, '0')} | {visualizeTime(process.countdown, false)}
                            </span></div>}

                            {/* { !process.type && <div className="time"><span onClick={onClickTimeWrap(process.id)}>
                            {process.startTime.hour.toString().padStart(2, '0')}:{process.startTime.minute.toString().padStart(2, '0')}
                            </span></div>} */}
                    </div>
                </div>



                {!mainPage && !readOnly &&
                <div className={cl.rightCorner}><div className={cl.taskCount}><div className={cl.taskCountInner}>{getTaskCountText()}</div></div><button className="delete-btn" onClick={clickSoundWrap(2, deleteProcessWrap(process.id))}>
                    <img src={require("../../shared/img/close.png")} alt="Delete Task"/>
                </button></div>}

                {mainPage && !readOnly &&
                 <div className={cl.rightCorner}><div className={cl.taskCount}>
                    <div className={cl.taskCountInner}>{getTaskCountText()}</div></div><button className="delete-btn" 
                    onClick={clickSoundWrap(2, () => {toggleCountdown()})}>
                   {!countdownActive && <img src={require("../../shared/img/start.png")} alt="Delete Task" style={{ marginLeft: '4px', height: '5vh', width: '5vh' }}/>}
                   {countdownActive && <img src={require("../../shared/img/pause.png")} alt="Delete Task" style={{ height: '5vh', width: '5vh' }}/>}
                </button></div>}


            </div>

            {renderTasks(process.tasks)}
        </React.Fragment>
    );
};

export default ProcessItem;
import React, {
    useEffect,
    useId,
    useRef,
    useState
} from 'react';
import {
    db
} from "../utils/db";

import getFullDate from "../utils/getFullDate";

function moveUp(array, index) {
    if (index > 0) {
        let tmp = array[index - 1];
        array[index - 1] = array[index];
        array[index] = tmp;
        return true;
    }
    return false;
}

function moveDown(array, index) {
    if (index < array.length - 1) {
        let tmp = array[index + 1];
        array[index + 1] = array[index];
        array[index] = tmp;
        return true;
    }
    return false;
}

const useOranizerData = () => {

    const init = {
        lastId: 1,
        templates: [],
        planned: {},
        history: []
    };

    const [iconDb, setIconDb] = useState({});
    const iconLoadingNow = useRef({});
    const [state, setState] = useState(init);
    const [loaded, setLoaded] = useState(false);

    const addImage = async (arrayBuffer, type) => {
        let checksum = await crypto.subtle.digest('SHA-1', arrayBuffer); //sha1
        checksum = Array.from(new Uint8Array(checksum));
        checksum = checksum.map((b) => b.toString(16).padStart(2, "0")).join("");
        checksum = btoa(checksum);
        let exists = await db.images.where({
            checksum
        }).first();
        if (exists) return exists.id;

        let id = await db.images.add({
            arrayBuffer,
            checksum,
            type
        });

        return id;
    };


    const tryGetImageCache = (id) => {
        if (!id) return undefined;
        if (iconDb.hasOwnProperty(id)) return iconDb[id];
        if (iconLoadingNow.current.hasOwnProperty(id)) return undefined;
        iconLoadingNow.current[id] = true;

        db.images.get(id).then(image => {
            let buffer = image.arrayBuffer;
            let blob = new Blob([buffer], {
                type: image.type
            }); // for db
            let url = (window.URL || window.webkitURL).createObjectURL(blob); // for now

            setIconDb(prev => {
                let newState = {
                    ...prev
                };
                newState[id] = url;
                delete iconLoadingNow.current[id];
                return newState;
            });
        });
    };

    const addProcessTemplate = (name, type, iconId, startTime, endTime) => {
        const obj = {
            name,
            type,
            iconId,
            startTime,
            endTime,
            tasks: []
        };
        setState(prev => {
            const cur = {
                ...prev,
                templates: [...prev.templates, {
                    ...obj,
                    id: prev.lastId
                }],
                lastId: prev.lastId + 1
            };
            save(cur);
            return cur;
        });
    };

    const removeProcessTemplate = (id) => {
        setState(prev => {
            const templates = prev.templates.filter(i => i.id !== id);
            const cur = {
                ...prev,
                templates
            };
            save(cur);
            return cur;
        });
    };

    const addTaskToProcessTemplate = (id, name) => {
        setState(prev => {
            let template = prev.templates.find(i => i.id === id);

            console.log(prev.templates)
            console.log(id)

            let task = {
                id: prev.lastId,
                name
            };

            template.tasks = [...template.tasks, task];
            let cur = {
                ...prev,
                lastId: prev.lastId + 1
            };
            save(cur);
            return cur;
        });
    };

    const removeTaskFromProcessTemplate = (processId, taskId) => {
        setState(prev => {
            let template = prev.templates.find(i => i.id === processId);
            template.tasks = template.tasks.filter(i => i.id !== taskId);

            let cur = {
                ...prev
            };
            save(cur);
            return cur;
        });
    };

    const moveUpTaskInProcessTemplate = (processId, taskId) => {
        setState(prev => {
            let n = structuredClone(prev);
            let template = n.templates.find(i => i.id === processId);
            let taskIndex = template.tasks.findIndex(i => i.id === taskId);
            if (!moveUp(template.tasks, taskIndex)) return n;

            save(n);
            return n;
        });
    };

    const moveDownTaskInProcessTemplate = (processId, taskId) => {
        setState(prev => {
            let n = structuredClone(prev);
            let template = n.templates.find(i => i.id === processId);
            let taskIndex = template.tasks.findIndex(i => i.id === taskId);
            if (!moveDown(template.tasks, taskIndex)) return n;

            save(n);
            return n;
        });
    };

    const moveUpTaskInProcess = (day, processId, taskId) => {
        setState(prev => {
            if (!prev.planned.hasOwnProperty(day)) return {
                ...prev
            };
            let n = structuredClone(prev);
            let template = n.planned[day].find(i => i.id === processId);
            let taskIndex = template.tasks.findIndex(i => i.id === taskId);
            if (!moveUp(template.tasks, taskIndex)) return n;

            save(n);
            return n;
        });
    };

    const moveDownTaskInProcess = (day, processId, taskId) => {
        setState(prev => {
            if (!prev.planned.hasOwnProperty(day)) return {
                ...prev
            };
            let n = structuredClone(prev);
            let template = n.planned[day].find(i => i.id === processId);
            let taskIndex = template.tasks.findIndex(i => i.id === taskId);
            if (!moveDown(template.tasks, taskIndex)) return n;

            save(n);
            return n;
        });
    };

    const addProcessToDay = (id, day, countdown, startTime) => {
        setState(prev => {
            const planned = prev.planned;
            let template;

            if (typeof id === 'number') {
                template = prev.templates.find(i => i.id === id);
            } else {
                template = prev.templates.find(i => i.id === id.id);
            }
            if (!countdown) countdown = 0;

            if (!planned.hasOwnProperty(day)) planned[day] = [];
            if (!planned[day]) planned[day] = [];

            //let filt = planned[day].find(i => i.id === prev.lastId);
            //if(filt !== 'undefined') return prev;
            //if(filt > 0) return;
            const existingProcessIndex = planned[day].findIndex(process => process.templateId === template.id);
            if (existingProcessIndex !== -1) {

                planned[day][existingProcessIndex].countdown = countdown;
                planned[day][existingProcessIndex].countdownTimer = countdown;
                return prev;
            }

            planned[day] = [...planned[day], {
                ...template,
                id: prev.lastId,
                templateId: template.id,
                tasks: [],
                countdown: (id.countdown) ? id.countdown : 0,
                countdownTimer: (id.countdown) ? id.countdown : 0,
                startTime: startTime || template.startTime,
                countDownActive: false,
                countStartTime: 0
            }];
            
            console.log(planned[day]);
            let cur = {
                ...prev,
                planned,
                lastId: prev.lastId + 1
            };

            save(cur);
            return cur;
        });
    };

    //     const addAnonProcessToDay = (day) => {

    //         setState(prev => {
    //             const planned = prev.planned;
    // countdown = 0;

    //             if (!planned.hasOwnProperty(day)) planned[day] = [];
    //             if (!planned[day]) planned[day] = [];

    //             //let filt = planned[day].find(i => i.id === prev.lastId);
    //             //if(filt !== 'undefined') return prev;
    //             //if(filt > 0) return;
    //             // const existingProcessIndex = planned[day].findIndex(process => process.templateId === template.id);
    //             // if (existingProcessIndex !== -1) {

    //             //     planned[day][existingProcessIndex].countdown = countdown;
    //             //     planned[day][existingProcessIndex].countdownTimer = countdown;
    //             //     return prev;
    //             // }

    //             planned[day] = [...planned[day], {
    //                 ...template,
    //                 id: prev.lastId,
    //                 templateId: 0,
    //                 tasks: [],
    //                 countdown: 0,
    //                 countdownTimer: 0,
    //                 startTime: startTime || template.startTime
    //             }];
    //             console.log(planned[day]);
    //             let cur = {
    //                 ...prev,
    //                 planned,
    //                 lastId: prev.lastId + 1
    //             };

    //             save(cur);
    //             return cur;
    //         });
    //         const obj = {
    //             name,
    //             type,
    //             iconId,
    //             startTime,
    //             endTime,
    //             tasks: []
    //         };
    //     }

    const setProcessToNextDay = (id, day) => {
        setState(prev => {
            const planned = prev.planned;
            let cur = {
                ...prev,
                planned
            };
            console.log(cur);
            return cur;
        });
        //     setState(prev =>
        //     {
        //         // const planned = prev.planned;
        //         // let template = prev.templates.find(i => i.id === id);


        //         console.log(prev);
        //         if(!planned.hasOwnProperty(day)) planned[day] = [];
        //         if(!planned[day]) planned[day] = [];
        //         planned[day] = [...planned[day], {...template, id: prev.lastId, templateId: template.id, tasks:[]}];

        //         let cur = {...prev, planned, lastId: prev.lastId + 1};
        //         save(cur);
        //         return cur;
        //     });
    };

    const removeProcessFromDay = (processId, day) => {
        setState(prev => {
            const planned = prev.planned;
            if (!planned.hasOwnProperty(day)) return {
                ...prev
            };
            planned[day] = planned[day].filter(i => i.id !== processId);

            let cur = {
                ...prev
            };
            save(cur);
            return cur;
        });
    };

    const addTaskToDay = (processId, taskId, day) => {
        setState(prev => {
            const planned = prev.planned;
            if (!planned.hasOwnProperty(day)) return {
                ...prev
            };
            let process = planned[day].find(i => i.id === processId);
            let processTemplateId = process.templateId;
            let template = prev.templates.find(i => i.id === processTemplateId);
            let taskTemplate = template.tasks.find(i => i.id === taskId);

            template.tasks = template.tasks.filter(i => i.id !== taskId);
            process.tasks = [...process.tasks, {
                ...taskTemplate,
                id: prev.lastId,
                templateId: taskTemplate.id
            }];

            let cur = {
                ...prev,
                lastId: prev.lastId + 1
            };
            save(cur);
            return cur;
        });
    };

    const removeTaskFromDay = (processId, taskId, day) => {
        setState(prev => {
            const planned = prev.planned;
            if (!planned.hasOwnProperty(day)) return {
                ...prev
            };
            let process = planned[day].find(i => i.id === processId);

            process.tasks = process.tasks.filter(i => i.id !== taskId);

            let cur = {
                ...prev
            };
            save(cur);
            return cur;
        });
    };

    const endCountdown = (day, processId, countdown) => {
        setState(prev => {
            let n = structuredClone(prev);

            const planned = n.planned;
            if (!planned.hasOwnProperty(day)) return n;
            let process = planned[day].find(i => i.id === processId);

            let num = process.countStartTime;


            process.countDownActive = false;
            //console.log("End Proc")
            //console.log(process)
            let value = process.countdownTimer - (Date.now() - process.countStartTime) / 1000
            //console.log(value);

            process.countStartTime = 0;
            process.countdownTimer = value;
            //changeCountdownTime(getFullDate(new Date()), process.id, value);
            // process.countStartTime = Date.now();

            //console.log(Date.now() - process.countStartTime);
            //console.log(process.countStartTime);
            save(n);
            return n;

        });
    };


    const startCountdown = (day, processId, countdown) => {
        setState(prev => {
            let n = structuredClone(prev);


            const planned = n.planned;
            if (!planned.hasOwnProperty(day)) return n;
            let process = planned[day].find(i => i.id === processId);
            // console.log(process)
            let activeProcess = planned[day].find(i => i.countDownActive == true);
            //console.log("Start Proc")
            //console.log(activeProcess);
            // //console.log(Object.keys(activeProcess).length)
            // if(activeProcess !== undefined && activeProcess.id != process.id) {
            //     console.log("Active proc")
            //     console.log(activeProcess)
            //     //endCountdown(day, activeProcess.id);
            // }

            try {

                //process.countDownActive = false;
                process.countDownActive = true;
                process.countStartTime = Date.now();
                console.log(process)

            } catch (error) {
                console.error(error);
                // Expected output: ReferenceError: nonExistentFunction is not defined
                // (Note: the exact output may be browser-dependent)
            }
            //endCountdown(day, processId);
            save(n);
            return n;

        });
    };

    const returnTaskFromDay = (processId, taskId, day) => {
        setState(prev => {
            const planned = prev.planned;
            if (!planned.hasOwnProperty(day)) return {
                ...prev
            };
            let process = planned[day].find(i => i.id === processId);

            let item = process.tasks.find(i => i.id == taskId)
            //process.tasks = process.tasks.filter(i => i.id !== taskId);

            console.log(process);
            // return {
            //     ...prev
            // };
            addTaskToProcessTemplate(process.templateId, item.name);
            removeTaskFromDay(processId, taskId, day);

            // console.log(item)
            return {
                ...prev
            };

            // let cur = {
            //     ...prev
            // };
            // save(cur);
            // return cur;
        });
    };



    const changeTimeInProcess = (day, processId, startTime, endTime, countdown) => {

        setState(prev => {
            let n = structuredClone(prev);
            const planned = n.planned;
            if (!planned.hasOwnProperty(day)) return n;
            let process = planned[day].find(i => i.id === processId);
            try {


                process.startTime = {
                    ...startTime
                };
                process.endTime = {
                    ...endTime
                };


                let countdownValue = countdown.countHours * 60 * 60 + countdown.countMinutes * 60;

                process.countdown = countdownValue

                process.countdownTimer = countdownValue

                save(n);
                //console.log(n)
            } catch (error) {
                console.error(error);
                // Expected output: ReferenceError: nonExistentFunction is not defined
                // (Note: the exact output may be browser-dependent)
            }
            return n;
        });
    };

    const changeCountdownTime = (day, processId, countdown) => {

        setState(prev => {

            let n = structuredClone(prev);


            const planned = n.planned;
            if (!planned.hasOwnProperty(day)) return n;
            let process = planned[day].find(i => i.id === processId);

            console.log(countdown)
            process.countdownTimer = countdown

            save(n);
            return n;
        });
    };


    const changeTimeInProcessTemplate = (processTemplateId, startTime, endTime) => {
        setState(prev => {
            let n = structuredClone(prev);
            const templates = n.templates;
            let process = templates.find(i => i.id === processTemplateId);
            process.startTime = {
                ...startTime
            };
            process.endTime = {
                ...endTime
            };

            save(n);
            return n;
        });
    };

    const markCompleteTaskInDay = (processId, taskId, day) => {
        setState(prev => {
            let n = structuredClone(prev);
            const planned = n.planned;
            if (!planned.hasOwnProperty(day)) return n;
            let process = planned[day].find(i => i.id === processId);
            let task = process.tasks.find(i => i.id === taskId);
            task.completed = true;

            // //add to history
            // n.history.unshift({
            //     iconId: process.iconId,
            //     name: task.name,
            //     startTime: {
            //         ...process.startTime
            //     },
            //     endTime: {
            //         ...process.endTime
            //     }
            // });

            save(n);
            return n;
        });
    };

    function save(data) {
        //localStorage.setItem("organizer", JSON.stringify(data));
        data = structuredClone(data);
        db.one.put(data, 1);
    }

    useEffect(() => {
        db.transaction('rw', db.one, async () => {
            let data = await db.one.toArray();

            if (!data || data.length === 0) {
                await db.one.put(state, 1);
                setLoaded(true);
                return;
            }
            setLoaded(true);
            setState(data[0]);
        })
    }, []);

    return {
        loaded: loaded,
        data: state,
        images: {
            addImage,
            tryGetImageCache
        },
        templates: {
            addProcessTemplate,
            removeProcessTemplate,
            addTaskToProcessTemplate,
            removeTaskFromProcessTemplate,
            moveUpTaskInProcessTemplate,
            moveDownTaskInProcessTemplate,
            changeTimeInProcessTemplate
        },
        week: {
            addProcessToDay,
            setProcessToNextDay,
            removeProcessFromDay,
            addTaskToDay,
            removeTaskFromDay,
            returnTaskFromDay,
            markCompleteTaskInDay,
            moveUpTaskInProcess,
            moveDownTaskInProcess,
            changeTimeInProcess,
            changeCountdownTime,
            startCountdown,
            endCountdown
        },
        history: {}
    };

};

export default useOranizerData;
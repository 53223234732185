import React, { useEffect, useState } from 'react';
import MainPage from "../Pages/MainPage/MainPage";
import TasksPage from "../Pages/TasksPage/TasksPage";
import WeekPage from "../Pages/WeekPage/WeekPage";
import HistoryPage from "../Pages/HistoryPage/HistoryPage";
import cl from "./Main.module.css";
import ModalWindow from "../ModalWindow/ModalWindow";
import clickSoundWrap from "../../utils/clickSound";
import useOranizerData from "../../hooks/useOranizerData";
import getFullDate from "../../utils/getFullDate";
import getDefaultIcon from "../../utils/getDefaultIcon";

const Main = () => {

    const [time, setTime] = useState(getTimestampInSeconds());
    const [activeAlarm, setActiveAlarm] = useState({});
    const [page, setPage] = useState('main');

    const [playedTime, setPlayedTime] = useState({hour: 0, minute: 0});

    const [showAlarm, setAlarm] = useState(false);
    const [clockInterval, setClockInterval] = useState(null);

    const organizer = useOranizerData();

    function showPage(page) {
        if (page === "main") return <MainPage organizer={organizer} />;
        else if (page === "tasks") return <TasksPage organizer={organizer} />;
        else if (page === "week") return <WeekPage organizer={organizer} />
        else if (page === "history") return <HistoryPage organizer={organizer} />
        else return <h3>Page Not Found</h3>;
    }

    function showLoading() {
        return <div className={cl.loading}>Загрузка из базы данных...</div>
    }

    function startClock(item) {
        setActiveAlarm(item);
        setAlarm(true);

        let audio = new Audio(require('../../shared/sounds/bell.mp3'));
        let interval = setInterval(() => {
            audio.play();
        }, 1000)

        setClockInterval(interval);
    }

    function closeAlarm() {
        clearInterval(clockInterval)
        //organizer.week.removeProcessFromDay(activeAlarm.id, getFullDate(new Date()));

        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        setPlayedTime({hour: hour, minute: minute})
        if(showAlarm) {
            // organizer.week.addProcessToDay(activeAlarm.templateId, getFullDate(tomorrow),
            //  activeAlarm.countdown, activeAlarm.startTime);
            setActiveAlarm({});
            setAlarm(false);
        }  
    }

    function getTimestampInSeconds() {
        return Math.floor(Date.now() / 1000)
    }

    useEffect(() => {
        const interval = setInterval(() => {

            const date = new Date();
            const hour = date.getHours();
            const minute = date.getMinutes();

            if (playedTime.hour == hour && playedTime.minute == minute) {
                return;
            }
           

            if (showAlarm) return;
            
            const now = new Date();

            if (!organizer.data.planned.hasOwnProperty(getFullDate(now))) {
                //setToShow([]);
                return;
            }

            const today = organizer.data.planned[getFullDate(now)];
            const split = getFullDate(now).split(".");

            let data = [];

            data = today.filter((i) => { return i.type === 'work' });

            data.forEach((i) => {
               
                if (i.startTime.hour == hour && i.startTime.minute == minute) {
                    startClock(i);
                    clearInterval(interval);
                    return;
                }
            })

        }, 1000); //1000);

        return () => clearInterval(interval);
    }, [showAlarm, organizer.data]);

    return (
        <div className={cl.main}>
            <section className="glass_box_black">
                <section className="glass_box d-flex column center_h ">

                    <div className={cl.pages}>
                        <div className={page === "main" ? cl.pageBtnSelected : cl.pageBtn} onClick={e => setPage("main")}><div className={cl.pageBtnInner} style={page === "main" ? { display: "none" } : {}} />Главная</div>
                        <div className={page === "tasks" ? cl.pageBtnSelected : cl.pageBtn} onClick={e => setPage("tasks")}><div className={cl.pageBtnInner} style={page === "tasks" ? { display: "none" } : {}} />Задачи</div>
                        <div className={page === "week" ? cl.pageBtnSelected : cl.pageBtn} onClick={e => setPage("week")}><div className={cl.pageBtnInner} style={page === "week" ? { display: "none" } : {}} />Неделя</div>
                        <div className={page === "history" ? cl.pageBtnSelected : cl.pageBtn} onClick={e => setPage("history")}><div className={cl.pageBtnInner} style={page === "history" ? { display: "none" } : {}} />Цели</div>
                    </div>

                    {organizer.loaded && showPage(page)}
                    {!organizer.loaded && showLoading()}

                </section>
            </section>

            {showAlarm && <div className="modal" onClick={e => e.stopPropagation()}>
                <div className={"modal-content " + cl.alarmModal}>
                    <section className="section2 d-flex column center_w center_h" id="section2">
                        <div className="task-content d-flex center_h jc-between">
                            <div className="image-container">
                                <img src={organizer.images.tryGetImageCache(activeAlarm.iconId) || getDefaultIcon()} loading={"lazy"} alt="Task Image" className="task-image" />
                            </div>
                        </div>
                    </section>
                    <section className="in_chat">
                        <div className={"text_info d-flex column jc-center " + cl.text_info}>
                            <div className="description-icon d-flex center_h">
                                <div className={"description " + cl.description}>{activeAlarm.name} </div>
                            </div>
                        </div>
                    </section>
                    <section className={"text_info d-flex column jc-center " + cl.text_info}>
                            <div className="description-icon d-flex center_h">
                                <div className={"time " + cl.time}>{activeAlarm.startTime.hour + " : " + activeAlarm.startTime.minute}</div>
                                {/* (activeAlarm.startTime.hour < 10) ? "0" + activeAlarm.startTime.hour : activeAlarm.startTime.hour
                                + " : " + 
                                (activeAlarm.startTime.minute < 10) ? "0" + activeAlarm.startTime.minute : activeAlarm.startTime.minute}</div> */}

                            </div>
                    </section>

                    <section className={"text_info d-flex column jc-center " + cl.text_info}>
                        <div className="text_info d-flex row jc-center">
                    <button id="cyber_btn" className="d-flex center_h center_w cyber_btn3" onClick={closeAlarm}>
                        Закрить
                    </button>
                    </div>
                    </section>
                </div>
            </div>}
        </div >
    );
};

export default Main;
import React, { useEffect } from 'react';
import clickSoundWrap from "../../utils/clickSound";

const ClickOnProcessModal = ({ windowType, args, onSelect, ...props }) => {

    function completeTask() {
        onSelect("mark_completed", args.processId, args.taskId);
    }

    function deleteTask() {
        onSelect("delete", args.processId, args.taskId);
    }

    function returnTask() {
        onSelect("return", args.processId, args.taskId);
    }
    

    function changeTaskType() {

    }

    useEffect(() => {
        window.document.body.style.overflow = "hidden";
        window.document.body.style.paddingRight = "13px";
        return () => {
            window.document.body.style.overflow = "";
            window.document.body.style.paddingRight = "";
        };
    }, []);

    return (
        <>
            <span className="close" onClick={clickSoundWrap(6, () => onSelect("close"))}>&times;</span>
            <section className="in_chat" id="in_chat">
                <div className="container">
                    <div className="top d-flex center_h jc-between">
                        {!args.hideCompleted && <button id="completeTaskBtn" className="cyber_btn2" onClick={clickSoundWrap(3, completeTask)}>
                            Миссия выполнена
                        </button>}
                        {windowType == "tasks"
                            ? <button id="completeTaskBtn" className="cyber_btn2" onClick={clickSoundWrap(3, deleteTask)}>
                                Удалить задачу
                            </button>
                            : <button id="completeTaskBtn" className="cyber_btn2" onClick={clickSoundWrap(6, returnTask)}>
                            Отменить миссию
                        </button>
                        }
                        {/*<button id="changeTaskTypeBtn" className="cyber_btn2" onClick={clickSoundWrap(4, changeTaskType)}>
                            Сменить тип
                        </button>*/}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClickOnProcessModal;
const clickSounds = [];

const clickSound1 = new Audio(require('../shared/sounds/sound1.mp3'));
const clickSound2 = new Audio(require('../shared/sounds/sound2.mp3'));
const clickSound3 = new Audio(require('../shared/sounds/sound3.mp3'));
const clickSound4 = new Audio(require('../shared/sounds/sound4.mp3'));
const clickSound5 = new Audio(require('../shared/sounds/sound5.mp3'));
const clickSound6 = new Audio(require('../shared/sounds/sound6.mp3'));
const bellSound = new Audio(require('../shared/sounds/bell.mp3'));

clickSounds.push(clickSound1);
clickSounds.push(clickSound2);
clickSounds.push(clickSound3);
clickSounds.push(clickSound4);
clickSounds.push(clickSound5);
clickSounds.push(clickSound6);
clickSounds.push(bellSound);

const clickSoundWrap = (soundId, callback) => {
    if(!clickSounds[soundId-1]) return callback;
    const sound = clickSounds[soundId-1];
    return (...args) =>
    {
        sound.play();
        if(callback) callback(...args);
    }
};

export default clickSoundWrap;
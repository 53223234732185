import React from 'react';
import clickSoundWrap from "../../utils/clickSound";
import cl from "./TaskItem.module.css";

const TaskItem = ({processId, i, moveTaskUp, moveTaskDown, clickOnTask, ...props}) =>
{
    function moveTaskUpWrap(processId, taskId)
    {
        if(props.readOnly) return;
        return (e) => {
            e.stopPropagation();
            moveTaskUp(processId, taskId)
        };
    }

    function moveTaskDownWrap(processId, taskId)
    {
        if(props.readOnly) return;
        return (e) => {
            e.stopPropagation();
            moveTaskDown(processId, taskId)
        };
    }

    function clickOnTaskWrap(processId, taskId)
    {
        if(props.readOnly) return;
        return (e) => {
            e.stopPropagation();
            clickOnTask(processId, taskId)
        };
    }

    return (
        <div className={i.completed ? "shadow_container_green" : "shadow_container"} onClick={clickSoundWrap(2,clickOnTaskWrap(processId, i.id))}>
            <p>{i.name}</p>
            <div className={cl.arrows} style={props.readOnly || props.hideArrows ? {display: "none"} : {}}>
                <img onClick={clickSoundWrap(2, moveTaskUpWrap(processId, i.id))} className={cl.arrowUp} src={require("../../shared/img/arrow_up.png")}/>
                <img onClick={clickSoundWrap(2, moveTaskDownWrap(processId, i.id))} className={cl.arrowDown}  src={require("../../shared/img/arrow_up.png")}/>
            </div>
        </div>
    );
};

export default TaskItem;
import React, {useEffect, useState} from 'react';
import clickSoundWrap from "../../utils/clickSound";

const AddTaskModal = ({args, onSelect, ...props}) => {

    const [name, setName] = useState("");

    function AddTask()
    {
        if(!name || name.length === 0) return;

        onSelect(args.processId, name);
    }

    useEffect(() =>
    {
        window.document.body.style.overflow = "hidden";
        window.document.body.style.paddingRight = "13px";
        return () =>
        {
            window.document.body.style.overflow = "";
            window.document.body.style.paddingRight = "";
        };
    }, []);

    useEffect(() =>
    {
        function onKeyUp(e)
        {
            if(e.keyCode === 13) AddTask();
        }

        window.addEventListener("keyup", onKeyUp);
        return () => window.removeEventListener("keyup", onKeyUp);
    }, [name, args.processId]);

    return (
        <>
            <span className="close" onClick={clickSoundWrap(6, () => onSelect("close"))}>&times;</span>
            <section className="in_chat" id="in_chat_subtask">
                <div className="container">
                    <div className="top d-flex center_h">
                        <input className="inputTextGeneral" type="text" id="subtaskInput" placeholder="Введите задачу..." value={name} onChange={e => setName(e.target.value)} />
                        <button id="cyber_btn" className="d-flex center_h center_w cyber_btn4" onClick={clickSoundWrap(1, AddTask)}>
                            Добавить
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddTaskModal;
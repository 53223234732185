import React, { useEffect, useRef, useState } from 'react';
import clickSoundWrap from "../../utils/clickSound";

const AddProcessModal = ({ args, onSelect, ...props }) => {
    const [name, setName] = useState("");
    const [startHour, setStartHour] = useState(0);
    const [startMinute, setStartMinute] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [endMinute, setEndMinute] = useState(0);

    const [icon, setIcon] = useState(null);

    const [type, setType] = useState("rest");

    function addTask() {

        if(type === "work") {
            
        }
        else if(type !== "rest") {
            if (startHour > endHour) return;
            if (startHour === endHour && startMinute >= endMinute) return;
        }

        
      
        if (icon) (window.URL || window.webkitURL).revokeObjectURL(icon.url);
        onSelect(name, type, icon, { hour: startHour, minute: startMinute }, { hour: endHour, minute: endMinute });
    }

    async function handleImageUpload(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];

            let buffer = await file.arrayBuffer();
            let blob = new Blob([buffer], { type: file.type }); // for db
            let url = (window.URL || window.webkitURL).createObjectURL(blob); // for now
            setIcon({ url, buffer, type: file.type });
        }
    }

    useEffect(() => {
        window.document.body.style.overflow = "hidden";
        window.document.body.style.paddingRight = "13px";
        return () => {
            window.document.body.style.overflow = "";
            window.document.body.style.paddingRight = "";
        };
    }, []);

    useEffect(() => {
        function onKeyUp(e) {
            if (e.keyCode === 13) addTask();
        }

        window.addEventListener("keyup", onKeyUp);
        return () => window.removeEventListener("keyup", onKeyUp);
    }, [name, type, icon, startHour, startMinute, endHour, endMinute]);

    return (
        <>
            <span className="close" onClick={clickSoundWrap(6, () => onSelect("close"))}>&times;</span>
            <section className="in_chat">
                <div className="container">
                    <div className="top d-flex center_h jc-between">
                        <div className="top_text d-flex center_h">
                            <input type="text" id="cyber_input" placeholder="Введите задачу..." value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        <button id="cyber_btn" className="d-flex center_h center_w cyber_btn3" onClick={clickSoundWrap(1, addTask)}>
                            Отправить
                        </button>
                    </div>
                </div>
            </section>
            <section className="section2 d-flex column center_w center_h" id="section2">
                <div className="time_and_image d-flex center_h jc-between">

                    {type !== "rest" && <div className="time_period">
                        
                    
                        <input type="text" maxLength={2} className={"cyber_input"} placeholder="Время от (часы)" value={startHour} onChange={e => setStartHour(parseInt(e.target.value) || 0)}/>
                        <input type="text" maxLength={2} className={"cyber_input"} style={{marginRight:"4vh"}} placeholder="Время от (минуты)" value={startMinute} onChange={e => setStartMinute(parseInt(e.target.value) || 0)}/>

                        <input type="text" maxLength={2} className={"cyber_input" + (type === "work" ? " hidden_item" : "")} placeholder="Время до (часы)" value={endHour} onChange={e => setEndHour(parseInt(e.target.value) || 0)} />
                        <input type="text" maxLength={2} className={"cyber_input" + (type === "work" ? " hidden_item" : "")} placeholder="Время до (минуты)" value={endMinute} onChange={e => setEndMinute(parseInt(e.target.value) || 0)} />
                    </div>}
                    <label htmlFor="imageInput" className="image-upload-btn" id="imageUploadBtn">
                        {!icon && <img src={require("../../shared/img/plus.png")} alt="Upload Image" className="default-image" />}
                        {icon && <img src={icon?.url} alt="Uploaded Image" className="added-image" />}
                    </label>
                    <input id="imageInput" className={"imageInput"} type="file" accept="image/*" onChange={handleImageUpload} />
                </div>
                <div className="task-type-buttons">
                    {/* <button className={"task-type-btn" + (type === "other" ? " selected" : "")} onClick={e => setType('other')}>Другое</button> */}
                    <button className={"task-type-btn" + (type === "rest" ? " selected" : "")} onClick={e => setType('rest')}>Обычная</button>
                    <button className={"task-type-btn" + (type === "work" ? " selected" : "")} onClick={e => setType('work')}>Циклическая</button>
                </div>
            </section>
        </>
    );
};

export default AddProcessModal;
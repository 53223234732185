import React, { useEffect } from 'react';
import getDefaultIcon from "../../utils/getDefaultIcon";
import cl from "../MainWindow/Countdown.module.css";

const ProcessBar = ({ data, organizer, setActiveProcess }) => {
    
    function onImageClick(id) {
        let result = data.filter(i => {
            if (i.id === id.id) return true;
            else return false;
        });
        localStorage.setItem('activeProcess', id.id)
        setActiveProcess(result[0]);
    }

    function checkForCompleted(item) {

        if (item.tasks.length > 0) {

            for (const el of item.tasks) {
                if (!el.completed) return true;

            }
        } else {
            return true;
        }

        return false;
    }

    return (
        // <div id="progressBar" ref={'bar'}>
        //     <div className="bar"></div>
        //     <br />
        //     <p>Time left <span id='timer' ref={'timer'}></span></p>
        // </div>

        <div className={cl.processBar}>
    {data.map(i => (
        <div className="incomplete-image">
            <img
            src={organizer.images.tryGetImageCache(i.iconId) || getDefaultIcon()}
            loading={"lazy"}
            alt="Task Image"
            className={checkForCompleted(i) ? "completed-image" : "incomplete-image"}
            onClick={() => onImageClick(i)}
        />
        </div>     
    ))}
</div>


    );

};

export default ProcessBar;
import React, { useEffect, useState } from 'react';
import clickSoundWrap from "../../utils/clickSound";
import TaskItem from "../ProcessItem/TaskItem";
import cl from "./AddExistingTaskModal.module.css";
import ProcessItem from "../ProcessItem/ProcessItem";

const AddExistingTaskModal = ({ organizer, args, setModal, onSelect, ...props }) => {

    const [selected, setSelected] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    const [anonProccess, setAnonProccess] = useState("hidden_process");

    const handleChange = (e) => {
        if(e.target.value == "") {
            setAnonProccess("hidden_process");
            return;
        }
        setAnonProccess(e.target.value); // Update the state with new input value
    };
    

    function AddTask(processId) {

        // console.log(args)
        // if (args.inWeekPage) {
        //     onSelect("selectTemplateTaks", { selected: selected });
        //     return;
        // }

        if (selected == null) return;
        if (args.type === "selectProcess" && processId) {
            //onSelect("selectTemplateTaks", processId);
            console.log(processId)
            onSelect("countdown_set", processId);
            return;
        }


        if (args.type === "selectTask") onSelect(args.type, args.processId, selected);
        else if (args.type === "selectProcess") onSelect(args.type, selected);
    }

    function addAnonProccess() {
        console.log('gf')
        setAnonProccess(" ");
    }

    function addTaskWrap(processId) {
        return (e) => {
            e.stopPropagation();
            if (!AddTask) return;
            if(anonProccess != 'hidden_process') {

            }

            AddTask(processId);
        };
    }

    function renderContent(selectedTask) {
        if(selectedTask) {
            
            let i = args.processes[0].tasks.filter((i) => {return i.id = selectedTask});
            console.log(i)

            return;
            //return <div key={i.id} className={selected === i.id ? cl.selected : cl.selectable} onClick={() => setSelected(i.id)}><TaskItem processId={args.processId} i={i} readOnly={true} /></div>
            return;
        } else if (!selectedTask && args.type === "selectTask") {
            if (args.tasks.length === 0) return <div className={cl.warningNotFound}>Нет доступных шаблонов задач для этого шаблона процесса</div>;
            return args.tasks.map(i =>
                <div key={i.id} className={selected === i.id ? cl.selected : cl.selectable} onClick={() => setSelected(i.id)}><TaskItem processId={args.processId} i={i} readOnly={true} /></div>
            );
        } else if (!selectedTask && args.type === "selectProcess") {
            if (args.processes.length === 0) return <div className={cl.warningNotFound}>Нет доступных шаблонов процессов</div>;
            return args.processes.map(i =>
                <div key={i.id} className={selected === i.id ? cl.selected : cl.selectable} 
                onClick={() => {setSelected(i.id); setSelectedType(i.type)}}><ProcessItem organizer={organizer} process={i} hideTasks={true} readOnly={true} /></div>
            );
        }
    }

    useEffect(() => {
        window.document.body.style.overflow = "hidden";
        window.document.body.style.paddingRight = "13px";
        return () => {
            window.document.body.style.overflow = "";
            window.document.body.style.paddingRight = "";
        };
    }, []);

    useEffect(() => {
        function onKeyUp(e) {
            if (e.keyCode === 13) AddTask();
        }

        window.addEventListener("keyup", onKeyUp);
        return () => window.removeEventListener("keyup", onKeyUp);
    }, [selected, args.type, args.processId]);

    return (
        <>
            <span className="close" onClick={clickSoundWrap(6, () => onSelect("close"))}>&times;</span>
            <section className="in_chat" id="in_chat_subtask" style={{ paddingRight: "1vh" }}>
                <div className="container">

                    <div style={{ overflowY: "auto", overflowX: "clip", maxHeight: "60vh" }}>
                        <div style={{ paddingRight: "2vh" }}>

                        {/* <div className="shadow_container_add" onClick={addAnonProccess}>

                        {anonProccess == "hidden_process" && <img  src={require("../../shared/img/plus.png")} alt="plus" />}
                        {anonProccess != "hidden_process" && <input type="text" onChange={handleChange} />}
                        </div> */}
                            {renderContent()}
                        </div>

                    </div>
                    {!((args.processes && args.processes.length === 0) || (args.tasks && args.tasks.length === 0)) && (
                        <button id="cyber_btn" className="d-flex center_h center_w cyber_btn4" onClick={clickSoundWrap(1, addTaskWrap(selected))}>
                            Добавить
                        </button>)}
                </div>
            </section>
        </>
    );
};

export default AddExistingTaskModal;
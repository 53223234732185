import React, { useState } from 'react';
import DaySlider from "../../DaySlider/DaySlider";
import clickSoundWrap from "../../../utils/clickSound";
import useOranizerData from "../../../hooks/useOranizerData";
import getFullDate from "../../../utils/getFullDate";
import ModalWindow from "../../ModalWindow/ModalWindow";
import ProcessItem from "../../ProcessItem/ProcessItem";

const WeekPage = ({ organizer }) => {

    const [currentDay, setCurrentDay] = useState(getFullDate(new Date()));
    const [modal, setModal] = useState({ type: "none", args: {} });

    const [activeSelect, setActiveSelect] = useState({});

    function onSelect(type, args) {
        if (args[0] === "countdown_set") {
            let data = organizer.data.templates.filter((i) => { return i.id === args[1] })[0];

            let processId = args[1];

            let startTime = { ...data.startTime };
            let endTime = { ...data.endTime };
            let countdownTime = true;           
            
            setActiveSelect(data);

            setModal({ type: "change_time", args: { processId, startTime, endTime, countdownTime } });

            // setModal({
            //     type: "change_time", args: {
            //         type: "selectTask",
            //         processId, tasks: data.tasks, inWeekPage: true
            //     }
            // });

            return;
        }
        // if (args[0] === "selectTemplateTaks") {

        //     if (args[1].selected) {
        //         addProcess(activeSelect, args[1].selected);            
        //         return;
        //     }

        //     let data = organizer.data.templates.filter((i) => { return i.id === args[1] })[0];
        //     let processId = args[0];
        //     // let process = organizer.data.planned[currentDay].find(i => i.id === processId);

        //     let startTime = { ...data.startTime };
        //     let endTime = { ...data.endTime };

        //     setActiveSelect(data);

        //     // setModal({
        //     //     type: "add_existing_task", args: {
        //     //         type: "selectTask",
        //     //         processId, tasks: data.tasks, inWeekPage: true
        //     //     }
        //     // });
        //     //setModal({ type: "change_time", args: { processId, startTime, endTime } });
        //     return;
        // }

        if (args[0] && args[0] === "close") {
            setModal("none");
            return;
        }

        if (type === "add_existing_task") {
            if (args[0] === "selectTask") {
                organizer.week.addTaskToDay(args[1], args[2], currentDay);
            }
            else if (args[0] === "selectProcess") {
                organizer.week.addProcessToDay(args[1], currentDay);
            }
        }
        else if (type === "change_time") {     
            if(args[0] === "selectProcess") {
                let result = activeSelect;
                result.countdown =  args[1];
                result.countdownTimer =  args[1];
                setActiveSelect(result)
                //console.log(activeSelect);
                organizer.week.addProcessToDay(activeSelect, currentDay);
                return;
            }

            console.log("gew")
            organizer.week.changeTimeInProcess(currentDay, args[0], args[1], args[2], args[3]);
        }
        else if (type === "click_on_process") {
            if (args[0] === "delete") {
                organizer.week.removeTaskFromDay(args[1], args[2], currentDay);
            }
            if (args[0] === "return") {
                organizer.week.returnTaskFromDay(args[1], args[2], currentDay);
            }
            else if (args[0] === "mark_completed") {
                organizer.week.markCompleteTaskInDay(args[1], args[2], currentDay);
            }
        }
        setModal("none");
    }

    function addProcess() {

        let processes = [...organizer.data.templates];
        if (organizer.data.planned.hasOwnProperty(currentDay)) processes = processes.filter(i => !organizer.data.planned[currentDay].find(j => j.templateId === i.id));
        setModal({ type: "add_existing_task", args: { type: "selectProcess", processes } });

        //setModal({ type: "add_existing_task", args: { type: "selectProcess", processes: [selectProcess], selectedTask: selectedTask } });

    }

    function addTask(processId) {
        let plannedProcess = organizer.data.planned[currentDay].find(i => i.id === processId);
        let processTemplateId = plannedProcess.templateId;
        setModal({ type: "add_existing_task", args: { type: "selectTask", processId, tasks: organizer.data.templates.find(i => i.id === processTemplateId).tasks } });
    }

    function deleteProcess(processId) {
        organizer.week.removeProcessFromDay(processId, currentDay);
    }

    function clickOnTask(processId, taskId) {
        let plannedProcess = organizer.data.planned[currentDay].find(i => i.id === processId)
        let plannedTask = plannedProcess.tasks.find(i => i.id === taskId);

        setModal({ type: "click_on_process", args: { processId, taskId, hideCompleted: plannedTask.completed } });
    }

    function moveTaskUp(processId, taskId) {
        organizer.week.moveUpTaskInProcess(currentDay, processId, taskId);
    }

    function moveTaskDown(processId, taskId) {
        organizer.week.moveDownTaskInProcess(currentDay, processId, taskId);
    }

    function onClickTime(processId, type) {
        let process = organizer.data.planned[currentDay].find(i => i.id === processId);

        let startTime = { ...process.startTime };
        let endTime = { ...process.endTime };
        console.log(process.countdown)
        let countdown = process.countdown;
        setModal({ type: "change_time", args: { processId, startTime, endTime, type, countdown } });
    }

    return (
        <>
            <section className="out_chat d-flex column center_h" id="out_chat">
                <DaySlider currentDate={currentDay} setCurrentDate={setCurrentDay} />
                <div className="todos-wrapper">
                    {organizer.data.planned.hasOwnProperty(currentDay) && organizer.data.planned[currentDay].map(i =>
                        <ProcessItem key={i.id} organizer={organizer} process={i} deleteProcess={deleteProcess} addTask={addTask} clickOnTask={clickOnTask}
                            moveTaskUp={moveTaskUp} moveTaskDown={moveTaskDown} onClickTime={onClickTime} />
                    )}
                    {!organizer.data.planned.hasOwnProperty(currentDay) && <div style={{ marginTop: "2vh", fontSize: "2vh", color: "#191e22", fontWeight: "1000" }}>Нет запланированных процессов</div>}
                </div>
            </section>
            <button className="cyber_btn" id="newQuestBtn" onClick={clickSoundWrap(2, addProcess)}>
                <img src={require("../../../shared/img/plus.png")} alt="plus" />
            </button>
            <ModalWindow organizer={organizer} type={modal.type}
                args={modal.args} onSelect={onSelect} />
        </>
    );
};

export default WeekPage;